import _ from "lodash";

// See https://schrott24.atlassian.net/wiki/spaces/SCHROTT24/pages/252477441/Log+an+event+using+dataLayer+and+Google+Analytics
// to know how to configure Google Tag Manager and Google Analytics
export const trackRedirection = (productId, products, quantity) => {
  if (!dataLayer) {
    return;
  }

  const product = _.find(products, p => p.materialId === parseInt(productId));
  const productName = product ? product.label : "-";

  dataLayer.push({
    event: "ProToSorec",
    productId,
    quantity,
    productName,
  });
};
